import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo, HasMany } from '@anny.co/vue-jsonapi-orm'
import { BBox } from 'geojson'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { LngLatLike } from 'mapbox-gl'
import { MapStartProperties } from '@/shared/components/maps/MapboxMap.vue'
import {
  FeatureSetTemplate,
  ResourceMapTemplates,
} from '@/bookingbuddy-admin/types/ResourceMapTypes'
import { ResourceMapLayer } from '@/shared/types/ResourceMapTypes'

export enum DisplayType {
  // Polygon geometry
  BUILDING = 'building',
  FURNITURE = 'furniture',
  RESOURCE = 'resource',
  ZONE = 'zone',
  // Line geometry
  TEXT = 'text',
  WALL = 'wall',
  DOOR = 'door',
  WINDOW = 'window',
  LINE = 'line',
  DASHEDLINE = 'dashed-line',
  // Point geometry
  LABEL = 'label',
  SYMBOL = 'symbol',

  // Relevant for floor planner only
  IMAGE = 'image',
  // Relevant for booking map only
  RESOURCEMARKER = 'resource-marker',
  MAPMARKER = 'map-marker',
}

export type ResourceMapAssets = {
  [index: string]: {
    svg: string
    png: string
  }
}

export type ResourceMapSettings = {
  extrusionView: boolean
}

export class ResourceMap extends ApiResource {
  static jsonApiType = 'resource-maps'
  @Attr() name: string

  @Attr() defaultLayer: string | null // id for default layer
  @Attr({}) assets: ResourceMapAssets // ASSET_ID: SVG_PATH
  @Attr([]) layers: ResourceMapLayer[]

  @Attr({ show: false, features: [] }) floorplanTemplates: ResourceMapTemplates
  @Attr([]) featureTemplates: FeatureSetTemplate[] | null
  @Attr(false) live?: boolean
  @Attr() zoom: number
  @Attr() pitch: number
  @Attr() bearing: number
  @Attr() latitude: number | null
  @Attr() longitude: number | null
  @Attr() boundingBox: BBox
  @Attr() settings: ResourceMapSettings

  @BelongsTo() location: Address | null
  @BelongsTo() organization: Organization
  @BelongsTo() model: Organization | Resource | null

  @HasMany() resources?: Resource[] | null
  @HasMany() mapLayers?: ResourceMapLayer[] | null

  /**
   * Returns resource map's default view
   */
  getMapStartProperties(): MapStartProperties {
    return {
      center: this.latitude
        ? [this.longitude!, this.latitude!]
        : ([this.location!.longitude, this.location!.latitude] as LngLatLike),
      zoom: this.zoom,
      pitch: this.pitch,
      bearing: this.bearing,
    }
  }
}
