import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { Service } from './Service'
import { ApiResource } from '../ApiResource'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { ResourceProperty } from '@/shared/jsonapi-orm/bookingbuddy/ResourceProperty'
import { Schedule } from '@/shared/jsonapi-orm/bookingbuddy/Schedule'
import { ExternalCalendar } from '@/shared/jsonapi-orm/bookingbuddy/ExternalCalendar'
import { Timeslot } from '@/shared/jsonapi-orm/bookingbuddy/Timeslot'
import { ResourcePreview } from '@/shared/types/bookings/BookingPreview'
import { RawLocation } from 'vue-router'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { AvailabilityInterval } from '@/shared/types/bookings/AvailabilityInterval'
import { ResourceMap } from '@/shared/jsonapi-orm/bookingbuddy/ResourceMap'
import { ResourceService } from '@/shared/jsonapi-orm/bookingbuddy/ResourceService'
import { Feature, Point } from '@turf/helpers'
import { MarkerPropertiesType } from '@/shared/assets/js/BookingMap/ResourceMarkerService'
import { MapStartProperties } from '@/shared/components/maps/MapboxMap.vue'
import { SubResourceBookingType } from '@/shared/jsonapi-orm/bookingbuddy/ServiceSubResource'
import { Integration } from '@/shared/jsonapi-orm/bookingbuddy/Integration'
import { BookingQuotaRestriction } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaRestriction'

export type ResourceCommunityRestriction = {
  can_view: boolean
  can_book: boolean
}

export type ResourceMetaSettings = {
  showAvailabilityCalendar: boolean
  showAvailabilityCount: boolean
  // new layout options
  showAvailability?: boolean
  showLocation?: boolean
  showServiceList?: boolean
  minimizeCoverImage?: boolean // small profile image

  checkIn: {
    isEnabled: boolean
    autoExpiry?: boolean
    autoExpiresAfter?: number
    allowedAdvancePeriod?: number
    allowedDelayPeriod?: number
    allowMultiple?: boolean
    releaseOnCheckout?: boolean
  }
  printLabels?: boolean
  checkout: {
    customerCanPickChildren: boolean
    requiresLogin: boolean
    requiresLoginReason: string
    verifyGuestEmail: boolean
  }
  selfCheckIn: {
    // check-in
    isEnabled: boolean
    disabledUntil: number
    allowedUntil: number
    autoCheckInEnabled: number
    // check out
    allowedOverrunTime: number
    checkOutEnabled: boolean
    // geo check
    geoCheckEnabled: boolean
    geoCheckMaxDelta: number
  }
  // unknown settings
  [key: string]: any
}

export type ServiceAvailabilityResult = {
  start_date: string
  end_date: string
  exact_match: boolean
  match_in_range: boolean
  display_quota: number
  display_booking_count: number
  service_id: number
  service_name: string
  duration: number
  available_intervals: AvailabilityInterval[]
  unavailability_type: string | null
  unavailability_message: string | null
}
export type ResourceAvailabilityResult = {
  start_date: string
  end_date: string
  exact_match: boolean
  match_in_range: boolean
  display_quota: number
  display_booking_count: number
  resource_id: number
  resource_name: string
  service_availability: ServiceAvailabilityResult[]
  all_ranges_exact_match: Record<string, boolean> | null
  exact_match_range_count: Record<string, number> | null
  total_range_count: number | null
}

interface MaxBookingsSettings {
  maxBookings: {
    perSlot: number
    perDay: number
    perWeek: number
    perMonth: number
  }
}

export interface ResourceRecommendationResult {
  id: string
  resource: {
    id: string
    slug: string
    name: string
    cover_image: string | null
    category: {
      id: string
      name: string
      icon: string
    }
  }
  preselected_service: null | {
    id: string
    slug: string
    name: string
  }
  services: {
    id: string
    slug: string
    name: string
    duration_preview: string
    price_preview: string
  }[]
  availability_result: ResourceAvailabilityResult
  original_start_date: string
  original_end_date: string
  booking_type: SubResourceBookingType
  relevant_start_date: string
  relevant_end_date: string
}

export class Resource extends ApiResource {
  static jsonApiType = 'resources'
  @Attr() slug: string | null
  @Attr() name: string | null
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr() description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr() plainDescription: string | null
  @Attr() color: string | null
  @Attr(true) autoAcceptBookings: boolean
  @Attr() previewToken: string
  @Attr() timezone: string
  @Attr(1) quantity: number
  @Attr(null) staggeredQuantity: number | null
  @Attr(1) maxBookingQuantity: number
  @Attr(10) maxSequenceQuantity: number
  @Attr(false) hasChildren: boolean
  @Attr(null) availableFrom: string | null
  @Attr(null) availableTo: string | null
  @Attr() latitude: number | null
  @Attr() longitude: number | null
  @Attr() hasMap: boolean
  @Attr() allowMultiService: boolean
  @Attr() createBookingSequence: boolean
  @Attr() onlineResource: boolean
  @Attr() isOnline: boolean

  // admin only
  @Attr() continuous: boolean
  @Attr() hidden?: boolean
  @Attr() archived: boolean
  @Attr() displayPublicDescription?: boolean
  @Attr() publicListing?: boolean
  @Attr() live?: boolean
  @Attr() seo?: boolean
  @Attr(false) viewingRequiresCommunity?: boolean
  @Attr(false) bookingRequiresCommunity?: boolean
  @Attr() settings?: ResourceMetaSettings & MaxBookingsSettings
  @Attr() emailInfo?: string | null
  @Attr({}) emailInfoI18n?: Record<string, string> | null
  @Attr() email: string | null
  @Attr(false) requiresQuota: boolean
  // relationships
  @HasMany() services?: Service[]
  @HasMany() resourceServices?: ResourceService[]
  @BelongsTo() group?: ResourceGroup | null
  @BelongsTo() category?: ResourceCategory | null
  @BelongsTo() parent?: this | null
  @HasMany() children?: this[]
  @BelongsTo() coverImage?: Image
  @HasMany() galleryImages?: Image[]
  @BelongsTo() organization?: Organization
  @HasMany() resourceProperties?: ResourceProperty[]
  @HasMany() featuredProperties?: ResourceProperty[]
  @HasMany() schedules?: Schedule[]
  @HasMany() timeslots?: Timeslot[]
  @HasMany() externalCalendars?: ExternalCalendar[]
  @HasMany() communities?: Community[]
  @HasMany() allCommunities?: Community[]
  @BelongsTo() location?: Address | null
  @HasMany() resourceMaps?: ResourceMap[]
  @HasMany() integrations?: Integration[]
  @HasOne() minimalService: Service | null
  @HasMany() bookingQuotaRestrictions?: BookingQuotaRestriction[] | null
  // meta
  @Meta() isAvailable: boolean | null
  @Meta() is_available: boolean | null
  @Meta() bookingCount: number | null
  @Meta() booking_count: number | null
  @Meta() servicesCount: number | null
  @Meta() services_count: number | null
  @Meta() numberAvailable: number | null
  @Meta() displayQuota: number | null
  @Meta() availabilityResult: ResourceAvailabilityResult | null
  @Meta() serviceCount: number
  @Meta() zoom: boolean
  @Meta() teams: boolean
  @Meta() metaSettings: ResourceMetaSettings
  @Meta() childrenCount: number
  @Meta() platformId: string | null
  @Meta() parentId: string | null

  @Meta() bookingRestricted: boolean
  @Meta() viewingRestricted: boolean
  // protected meta
  @Meta() clientUrl?: string
  @Meta() previewUrl?: string

  /**
   * Create child resources and return self with updated quantity.
   * @param childData
   */
  async creteChildren(childData: { name: string }[]): Promise<Resource> {
    const { data } = await this.api().request('children', 'post', {
      data: {
        child_resources: childData,
      },
    })
    return this.apiService.resourceFromResponse<Resource>(data).data
  }

  /**
   * Duplicate instance and its relationships.
   */
  async duplicate(): Promise<Resource> {
    const { data } = await this.api()
      .include(['category', 'coverImage', 'resourceProperties.property'])
      .request('duplicate')
    return Resource.resourceFromResponse(data, this.apiService).data
  }

  /**
   * Valiedate slug value.
   * @param slug
   */
  async validateSlug(slug: string): Promise<boolean> {
    try {
      const { data } = await this.api()
        .query({
          slug,
        })
        .request('validate-slug')
      return data && data.is_available
    } catch (e) {
      console.log(e)
      return true
    }
  }

  getShopLocalPathObject(): RawLocation {
    return {
      name: 'book-resourceSlug',
      params: { resourceSlug: this.slug! },
    }
  }

  /**
   * Get preview data.
   */
  getPreviewData(): ResourcePreview {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      timezone: this.timezone,
      quantity: this.quantity,
      hasChildren: this.hasChildren,
      hidden: this.hidden,
    }
  }

  /**
   * Check restrictions for currently active account.
   */
  async getCommunityRestrictions(
    previewToken?: string | null
  ): Promise<ResourceCommunityRestriction> {
    const { data }: { data: ResourceCommunityRestriction } = await this.api()
      .query({
        preview_token: previewToken,
      })
      .request('community-restrictions')
    return data
  }
}

export interface ResourceLocation {
  name: string
  id: string
  slug: string
  parentId: string | null
  parentSlug: string | null
  organizationId: string
  lat: number | null
  lng: number | null
  quantity: number
  hasChildren: boolean
  hasMap: boolean
  isOnline: boolean
  bookingRestricted: boolean
  resourceMapIds?: string[] | null
  resourceMapLayers: Record<string, string>
  availabilityResult: ResourceAvailabilityResult | null // show availability result
}

export type ResourceMapData = Pick<
  ResourceMap,
  | 'id'
  | 'name'
  | 'assets'
  | 'defaultLayer'
  | 'layers'
  | 'boundingBox'
  | 'latitude'
  | 'longitude'
  | 'zoom'
  | 'pitch'
  | 'bearing'
> & { geojsonUrls: Record<string, string>; extrusionEnabled: boolean }

export type MapMarkersData = Record<
  string,
  Feature<Point, MarkerPropertiesType>
>

export interface ResourceLocationResponse {
  resourceLocations: ResourceLocation[]
  resourceMaps: ResourceMapData[]
  markers: MapMarkersData
  position: MapStartProperties
}
