import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faGoogle as freeFabFaGoogle,
  faYahoo as freeFabFaYahoo,
  faAndroid as freeFabFaAndroid,
  faApple as freeFabFaApple,
  faFacebookSquare as freeFabFaFacebookSquare,
  faInstagramSquare as freeFabFaInstagramSquare,
  faLinkedin as freeFabFaLinkedin,
  faWhatsapp as freeFabFaWhatsapp,
  faTelegram as freeFabFaTelegram
} from '@fortawesome/free-brands-svg-icons'

import {
  faUsersViewfinder as proFasFaUsersViewfinder,
  faMinus as proFasFaMinus,
  faPlus as proFasFaPlus,
  faPresentation as proFasFaPresentation,
  faStar as proFasFaStar,
  faHeart as proFasFaHeart,
  faMugTea as proFasFaMugTea,
  faMugHot as proFasFaMugHot,
  faGlassWhiskeyRocks as proFasFaGlassWhiskeyRocks,
  faMoon as proFasFaMoon,
  faLocationDot as proFasFaLocationDot,
  faSearchLocation as proFasFaSearchLocation,
  faAngleLeft as proFasFaAngleLeft,
  faShare as proFasFaShare,
  faSunBright as proFasFaSunBright,
  faGripVertical as proFasFaGripVertical
} from '@fortawesome/pro-solid-svg-icons'

import {
  faBellRing as proFarFaBellRing,
  faBellSlash as proFarFaBellSlash,
  faFileContract as proFarFaFileContract,
  faLockOpen as proFarFaLockOpen,
  faCog as proFarFaCog,
  faBuilding as proFarFaBuilding,
  faClone as proFarFaClone,
  faRepeat as proFarFaRepeat,
  faCoins as proFarFaCoins,
  faCreditCard as proFarFaCreditCard,
  faUserMagnifyingGlass as proFarFaUserMagnifyingGlass,
  faEye as proFarFaEye,
  faArrowRightFromBracket as proFarFaArrowRightFromBracket,
  faBookOpen as proFarFaBookOpen,
  faShuffle as proFarFaShuffle,
  faBolt as proFarFaBolt,
  faExclamation as proFarFaExclamation,
  faCircleQuestion as proFarFaCircleQuestion,
  faUserLock as proFarFaUserLock,
  faUserMinus as proFarFaUserMinus,
  faUserCircle as proFarFaUserCircle,
  faTrashAlt as proFarFaTrashAlt,
  faClockRotateLeft as proFarFaClockRotateLeft,
  faRotateLeft as proFarFaRotateLeft,
  faInfinity as proFarFaInfinity,
  faDiagramNested as proFarFaDiagramNested,
  faListCheck as proFarFaListCheck,
  faBarsSort as proFarFaBarsSort,
  faListAlt as proFarFaListAlt,
  faAngleDown as proFarFaAngleDown,
  faAngleUp as proFarFaAngleUp,
  faArrowRight as proFarFaArrowRight,
  faArrowLeft as proFarFaArrowLeft,
  faSpinnerThird as proFarFaSpinnerThird,
  faTimes as proFarFaTimes,
  faCheck as proFarFaCheck,
  faClock as proFarFaClock,
  faLock as proFarFaLock,
  faEllipsisH as proFarFaEllipsisH,
  faPlus as proFarFaPlus,
  faAngleRight as proFarFaAngleRight,
  faAngleLeft as proFarFaAngleLeft,
  faAngleDoubleRight as proFarFaAngleDoubleRight,
  faShieldCheck as proFarFaShieldCheck,
  faBadgeCheck as proFarFaBadgeCheck,
  faHandPaper as proFarFaHandPaper,
  faBan as proFarFaBan,
  faHistory as proFarFaHistory,
  faSearch as proFarFaSearch,
  faCalendar as proFarFaCalendar,
  faMinus as proFarFaMinus,
  faInfoCircle as proFarFaInfoCircle,
  faInfoSquare as proFarFaInfoSquare,
  faCalendarPlus as proFarFaCalendarPlus,
  faEdit as proFarFaEdit,
  faCalendarCheck as proFarFaCalendarCheck,
  faSignIn as proFarFaSignIn,
  faSignOut as proFarFaSignOut,
  faHeadset as proFarFaHeadset,
  faQuestion as proFarFaQuestion,
  faCheckCircle as proFarFaCheckCircle,
  faBallotCheck as proFarFaBallotCheck,
  faSwatchbook as proFarFaSwatchbook,
  faCubes as proFarFaCubes,
  faExclamationCircle as proFarFaExclamationCircle,
  faCommentAltLines as proFarFaCommentAltLines,
  faUserClock as proFarFaUserClock,
  faCloudDownload as proFarFaCloudDownload,
  faFilePdf as proFarFaFilePdf,
  faRedo as proFarFaRedo,
  faExpandAlt as proFarFaExpandAlt,
  faUndo as proFarFaUndo,
  faExternalLink as proFarFaExternalLink,
  faLocation as proFarFaLocation,
  faReceipt as proFarFaReceipt,
  faMoneyBill as proFarFaMoneyBill,
  faTag as proFarFaTag,
  faGripLines as proFarFaGripLines,
  faBars as proFarFaBars,
  faPencil as proFarFaPencil,
  faUser as proFarFaUser,
  faStar as proFarFaStar,
  faBullhorn as proFarFaBullhorn,
  faPaperPlane as proFarFaPaperPlane,
  faExternalLinkSquare as proFarFaExternalLinkSquare,
  faShieldVirus as proFarFaShieldVirus,
  faCircle as proFarFaCircle,
  faLocationDot as proFarFaLocationDot,
  faMapMarker as proFarFaMapMarker,
  faCompass as proFarFaCompass,
  faGlobe as proFarFaGlobe,
  faLocationArrow as proFarFaLocationArrow,
  faUserTimes as proFarFaUserTimes,
  faCopy as proFarFaCopy,
  faUserEdit as proFarFaUserEdit,
  faSms as proFarFaSms,
  faAngleDoubleDown as proFarFaAngleDoubleDown,
  faCommentSlash as proFarFaCommentSlash,
  faFileImage as proFarFaFileImage,
  faFileDownload as proFarFaFileDownload,
  faLink as proFarFaLink,
  faCrown as proFarFaCrown,
  faShield as proFarFaShield,
  faEnvelope as proFarFaEnvelope,
  faComment as proFarFaComment,
  faQrcode as proFarFaQrcode,
  faCalendarStar as proFarFaCalendarStar,
  faHeart as proFarFaHeart,
  faHeartCircleCheck as proFarFaHeartCircleCheck,
  faListRadio as proFarFaListRadio,
  faShare as proFarFaShare,
  faPaintRoller as proFarFaPaintRoller,
  faArrowUpFromSquare as proFarFaArrowUpFromSquare,
  faCartArrowDown as proFarFaCartArrowDown,
  faHourglassStart as proFarFaHourglassStart,
  faHourglassHalf as proFarFaHourglassHalf,
  faHourglassEnd as proFarFaHourglassEnd,
  faHourglass as proFarFaHourglass,
  faShoppingCart as proFarFaShoppingCart,
  faArrowCircleLeft as proFarFaArrowCircleLeft,
  faMap as proFarFaMap,
  faSlidersSimple as proFarFaSlidersSimple,
  faChevronDown as proFarFaChevronDown,
  faChevronUp as proFarFaChevronUp,
  faGear as proFarFaGear,
  faSunBright as proFarFaSunBright,
  faMoon as proFarFaMoon,
  faX as proFarFaX,
  faUserSecret as proFarFaUserSecret,
  faMapMarkedAlt as proFarFaMapMarkedAlt,
  faArrowUpRightFromSquare as proFarFaArrowUpRightFromSquare,
  faBell as proFarFaBell,
  faFilter as proFarFaFilter,
  faCalendars as proFarFaCalendars,
  faSave as proFarFaSave,
  faStore as proFarFaStore,
  faFaceAwesome as proFarFaFaceAwesome,
  faCherries as proFarFaCherries,
  faArrowTurnDownLeft as proFarFaArrowTurnDownLeft,
  faDice as proFarFaDice,
  faDiceOne as proFarFaDiceOne,
  faDiceTwo as proFarFaDiceTwo,
  faDiceThree as proFarFaDiceThree,
  faDiceFour as proFarFaDiceFour,
  faDiceFive as proFarFaDiceFive,
  faDiceSix as proFarFaDiceSix,
  faSyncAlt as proFarFaSyncAlt,
  faCircleXmark as proFarFaCircleXmark
} from '@fortawesome/pro-regular-svg-icons'

import {
  faExternalLink as proFalFaExternalLink,
  faArrowToLeft as proFalFaArrowToLeft,
  faArrowToRight as proFalFaArrowToRight,
  faTimes as proFalFaTimes,
  faEdit as proFalFaEdit,
  faInfoCircle as proFalFaInfoCircle
} from '@fortawesome/pro-light-svg-icons'

import {
  faImages as proFadFaImages,
  faAngleDown as proFadFaAngleDown,
  faAngleUp as proFadFaAngleUp,
  faAngleRight as proFadFaAngleRight,
  faSpinnerThird as proFadFaSpinnerThird,
  faCalendar as proFadFaCalendar,
  faCalendarCheck as proFadFaCalendarCheck,
  faTimes as proFadFaTimes,
  faSearch as proFadFaSearch,
  faExclamationCircle as proFadFaExclamationCircle,
  faLock as proFadFaLock,
  faCheckCircle as proFadFaCheckCircle,
  faBan as proFadFaBan,
  faExternalLink as proFadFaExternalLink,
  faFilter as proFadFaFilter,
  faPlusCircle as proFadFaPlusCircle,
  faMinusCircle as proFadFaMinusCircle,
  faTrash as proFadFaTrash,
  faTrashAlt as proFadFaTrashAlt,
  faShoppingCart as proFadFaShoppingCart,
  faHourglassStart as proFadFaHourglassStart,
  faHourglassHalf as proFadFaHourglassHalf,
  faHourglassEnd as proFadFaHourglassEnd,
  faHourglass as proFadFaHourglass,
  faTags as proFadFaTags,
  faCalendarPlus as proFadFaCalendarPlus,
  faEnvelope as proFadFaEnvelope,
  faSortAmountUp as proFadFaSortAmountUp,
  faSortAmountDown as proFadFaSortAmountDown,
  faExclamationSquare as proFadFaExclamationSquare,
  faHistory as proFadFaHistory,
  faInfoCircle as proFadFaInfoCircle,
  faPhoneOffice as proFadFaPhoneOffice,
  faMailbox as proFadFaMailbox,
  faTimesCircle as proFadFaTimesCircle,
  faClone as proFadFaClone,
  faSwatchbook as proFadFaSwatchbook,
  faTasks as proFadFaTasks,
  faRedo as proFadFaRedo,
  faStore as proFadFaStore,
  faPortalEnter as proFadFaPortalEnter,
  faPortalExit as proFadFaPortalExit,
  faArrowCircleLeft as proFadFaArrowCircleLeft,
  faEdit as proFadFaEdit,
  faMugHot as proFadFaMugHot,
  faMugTea as proFadFaMugTea,
  faHeart as proFadFaHeart,
  faKeySkeleton as proFadFaKeySkeleton,
  faPencil as proFadFaPencil,
  faClipboard as proFadFaClipboard,
  faClipboardCheck as proFadFaClipboardCheck,
  faBullhorn as proFadFaBullhorn,
  faShieldVirus as proFadFaShieldVirus,
  faMapMarkedAlt as proFadFaMapMarkedAlt,
  faCaretLeft as proFadFaCaretLeft,
  faCaretRight as proFadFaCaretRight,
  faAlarmClock as proFadFaAlarmClock,
  faUser as proFadFaUser,
  faCalendarExclamation as proFadFaCalendarExclamation,
  faLoader as proFadFaLoader,
  faCodePullRequest as proFadFaCodePullRequest,
  faUserTieHair as proFadFaUserTieHair,
  faCircleParking as proFadFaCircleParking,
  faShopLock as proFadFaShopLock,
  faTicket as proFadFaTicket,
  faMap as proFadFaMap,
  faTableList as proFadFaTableList
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  freeFabFaGoogle,
  freeFabFaYahoo,
  freeFabFaAndroid,
  freeFabFaApple,
  freeFabFaFacebookSquare,
  freeFabFaInstagramSquare,
  freeFabFaLinkedin,
  freeFabFaWhatsapp,
  freeFabFaTelegram,
  proFasFaUsersViewfinder,
  proFasFaMinus,
  proFasFaPlus,
  proFasFaPresentation,
  proFasFaStar,
  proFasFaHeart,
  proFasFaMugTea,
  proFasFaMugHot,
  proFasFaGlassWhiskeyRocks,
  proFasFaMoon,
  proFasFaLocationDot,
  proFasFaSearchLocation,
  proFasFaAngleLeft,
  proFasFaShare,
  proFasFaSunBright,
  proFasFaGripVertical,
  proFarFaBellRing,
  proFarFaBellSlash,
  proFarFaFileContract,
  proFarFaLockOpen,
  proFarFaCog,
  proFarFaBuilding,
  proFarFaClone,
  proFarFaRepeat,
  proFarFaCoins,
  proFarFaCreditCard,
  proFarFaUserMagnifyingGlass,
  proFarFaEye,
  proFarFaArrowRightFromBracket,
  proFarFaBookOpen,
  proFarFaShuffle,
  proFarFaBolt,
  proFarFaExclamation,
  proFarFaCircleQuestion,
  proFarFaUserLock,
  proFarFaUserMinus,
  proFarFaUserCircle,
  proFarFaTrashAlt,
  proFarFaClockRotateLeft,
  proFarFaRotateLeft,
  proFarFaInfinity,
  proFarFaDiagramNested,
  proFarFaListCheck,
  proFarFaBarsSort,
  proFarFaListAlt,
  proFarFaAngleDown,
  proFarFaAngleUp,
  proFarFaArrowRight,
  proFarFaArrowLeft,
  proFarFaSpinnerThird,
  proFarFaTimes,
  proFarFaCheck,
  proFarFaClock,
  proFarFaLock,
  proFarFaEllipsisH,
  proFarFaPlus,
  proFarFaAngleRight,
  proFarFaAngleLeft,
  proFarFaAngleDoubleRight,
  proFarFaShieldCheck,
  proFarFaBadgeCheck,
  proFarFaHandPaper,
  proFarFaBan,
  proFarFaHistory,
  proFarFaSearch,
  proFarFaCalendar,
  proFarFaMinus,
  proFarFaInfoCircle,
  proFarFaInfoSquare,
  proFarFaCalendarPlus,
  proFarFaEdit,
  proFarFaCalendarCheck,
  proFarFaSignIn,
  proFarFaSignOut,
  proFarFaHeadset,
  proFarFaQuestion,
  proFarFaCheckCircle,
  proFarFaBallotCheck,
  proFarFaSwatchbook,
  proFarFaCubes,
  proFarFaExclamationCircle,
  proFarFaCommentAltLines,
  proFarFaUserClock,
  proFarFaCloudDownload,
  proFarFaFilePdf,
  proFarFaRedo,
  proFarFaExpandAlt,
  proFarFaUndo,
  proFarFaExternalLink,
  proFarFaLocation,
  proFarFaReceipt,
  proFarFaMoneyBill,
  proFarFaTag,
  proFarFaGripLines,
  proFarFaBars,
  proFarFaPencil,
  proFarFaUser,
  proFarFaStar,
  proFarFaBullhorn,
  proFarFaPaperPlane,
  proFarFaExternalLinkSquare,
  proFarFaShieldVirus,
  proFarFaCircle,
  proFarFaLocationDot,
  proFarFaMapMarker,
  proFarFaCompass,
  proFarFaGlobe,
  proFarFaLocationArrow,
  proFarFaUserTimes,
  proFarFaCopy,
  proFarFaUserEdit,
  proFarFaSms,
  proFarFaAngleDoubleDown,
  proFarFaCommentSlash,
  proFarFaFileImage,
  proFarFaFileDownload,
  proFarFaLink,
  proFarFaCrown,
  proFarFaShield,
  proFarFaEnvelope,
  proFarFaComment,
  proFarFaQrcode,
  proFarFaCalendarStar,
  proFarFaHeart,
  proFarFaHeartCircleCheck,
  proFarFaListRadio,
  proFarFaShare,
  proFarFaPaintRoller,
  proFarFaArrowUpFromSquare,
  proFarFaCartArrowDown,
  proFarFaHourglassStart,
  proFarFaHourglassHalf,
  proFarFaHourglassEnd,
  proFarFaHourglass,
  proFarFaShoppingCart,
  proFarFaArrowCircleLeft,
  proFarFaMap,
  proFarFaSlidersSimple,
  proFarFaChevronDown,
  proFarFaChevronUp,
  proFarFaGear,
  proFarFaSunBright,
  proFarFaMoon,
  proFarFaX,
  proFarFaUserSecret,
  proFarFaMapMarkedAlt,
  proFarFaArrowUpRightFromSquare,
  proFarFaBell,
  proFarFaFilter,
  proFarFaCalendars,
  proFarFaSave,
  proFarFaStore,
  proFarFaFaceAwesome,
  proFarFaCherries,
  proFarFaArrowTurnDownLeft,
  proFarFaDice,
  proFarFaDiceOne,
  proFarFaDiceTwo,
  proFarFaDiceThree,
  proFarFaDiceFour,
  proFarFaDiceFive,
  proFarFaDiceSix,
  proFarFaSyncAlt,
  proFarFaCircleXmark,
  proFalFaExternalLink,
  proFalFaArrowToLeft,
  proFalFaArrowToRight,
  proFalFaTimes,
  proFalFaEdit,
  proFalFaInfoCircle,
  proFadFaImages,
  proFadFaAngleDown,
  proFadFaAngleUp,
  proFadFaAngleRight,
  proFadFaSpinnerThird,
  proFadFaCalendar,
  proFadFaCalendarCheck,
  proFadFaTimes,
  proFadFaSearch,
  proFadFaExclamationCircle,
  proFadFaLock,
  proFadFaCheckCircle,
  proFadFaBan,
  proFadFaExternalLink,
  proFadFaFilter,
  proFadFaPlusCircle,
  proFadFaMinusCircle,
  proFadFaTrash,
  proFadFaTrashAlt,
  proFadFaShoppingCart,
  proFadFaHourglassStart,
  proFadFaHourglassHalf,
  proFadFaHourglassEnd,
  proFadFaHourglass,
  proFadFaTags,
  proFadFaCalendarPlus,
  proFadFaEnvelope,
  proFadFaSortAmountUp,
  proFadFaSortAmountDown,
  proFadFaExclamationSquare,
  proFadFaHistory,
  proFadFaInfoCircle,
  proFadFaPhoneOffice,
  proFadFaMailbox,
  proFadFaTimesCircle,
  proFadFaClone,
  proFadFaSwatchbook,
  proFadFaTasks,
  proFadFaRedo,
  proFadFaStore,
  proFadFaPortalEnter,
  proFadFaPortalExit,
  proFadFaArrowCircleLeft,
  proFadFaEdit,
  proFadFaMugHot,
  proFadFaMugTea,
  proFadFaHeart,
  proFadFaKeySkeleton,
  proFadFaPencil,
  proFadFaClipboard,
  proFadFaClipboardCheck,
  proFadFaBullhorn,
  proFadFaShieldVirus,
  proFadFaMapMarkedAlt,
  proFadFaCaretLeft,
  proFadFaCaretRight,
  proFadFaAlarmClock,
  proFadFaUser,
  proFadFaCalendarExclamation,
  proFadFaLoader,
  proFadFaCodePullRequest,
  proFadFaUserTieHair,
  proFadFaCircleParking,
  proFadFaShopLock,
  proFadFaTicket,
  proFadFaMap,
  proFadFaTableList
)

config.autoAddCss = false

Vue.component('fa', FontAwesomeIcon)
Vue.component('faLayers', FontAwesomeLayers)
Vue.component('faLayersText', FontAwesomeLayersText)
